import type { FC } from 'react';
import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import uuid from 'uuid/v4';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';

import { useConfluencePageAri } from '@atlassian/ai-summary';
import { usePopupDwellTime } from '@atlassian/popup-dwell-time';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';
import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import { AIStreamLoading, AIBorder, AISummaryLoading } from '@atlassian/ai-components';
import { useAIAgentStreaming, AIAgentResponseState } from '@atlassian/ai-agent-streaming';
import type { AIAgentStreamingState } from '@atlassian/ai-agent-streaming';

import type { SummaryAnalyticsPropertiesType } from '@confluence/quick-summary';
import { SmartsSummaryErrorComponent } from '@confluence/quick-summary';
import { useSessionData } from '@confluence/session-data';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import {
	AI_CONTENT_CATCHUP_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { createSingleQueryParamHook } from '@confluence/route-manager';
import { expValEqualsNoExposure, manuallyLogExpExposure } from '@confluence/feature-experiments';

import { PageCatchupComponent } from './platform-ui/PageCatchupComponent';
import { PageCatchupMetadataQuery } from './PageCatchupMetadataQuery.graphlql';
import { CatchupContentType } from './__types__/PageCatchupMetadataQuery';
import type {
	PageCatchupMetadataQuery as PageCatchupMetaDataResponseType,
	PageCatchupMetadataQueryVariables,
} from './__types__/PageCatchupMetadataQuery';
import { mapToEditors } from './data/mapToEditors';
import { mapToFormattedDate } from './data/mapToFormattedDate';
import { AI_CONTENT_CATCHUP_INTERACTION_METRIC } from './perf.config';
import { useGetVersionHistoryLink } from './useGetVersionHistoryLink';
import type {
	PageCatchupVersionDiffMetadataForContentQuery as PageCatchupVersionDiffMetadataResponseType,
	PageCatchupVersionDiffMetadataForContentQueryVariables,
} from './__types__/PageCatchupVersionDiffMetadataForContentQuery';
import { PageCatchupVersionDiffMetadataForContentQuery } from './PageCatchupVersionDiffMetadataForContentQuery.graphql';
import type {
	PageCatchupWrapperRequest,
	InlineLinkingAgentResponse,
} from './__types__/PageCatchupWrapperAIAgentStreamingTypes';
import { mapInlineLinkingAgentResponseToMarkdown } from './data/mapInlineLinkingAgentResponseToMarkdown';
import { useInlineLinkingStreaming } from './data/useInlineLinkingStreaming';

const useOpenContentCatchupParam = createSingleQueryParamHook('openContentCatchup');

type PageCatchupWrapperComponentProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	source?: string;
	selectedVersions?: Set<any>;
	isInObjectSidebar?: boolean;
};

export const PageCatchupWrapperComponent: FC<PageCatchupWrapperComponentProps> = ({
	contentId,
	contentType,
	spaceKey,
	source,
	selectedVersions,
	isInObjectSidebar,
}) => {
	const { locale } = useIntl();
	const { cloudId } = useSessionData();
	const { pageAri } = useConfluencePageAri(cloudId, contentId, contentType);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [aiInteractionId] = useState(uuid());
	const experienceTracker = useContext(ExperienceTrackerContext);
	const catchUpContentRef = useRef<HTMLDivElement | null>(null);
	const { getDwellTime } = usePopupDwellTime(catchUpContentRef);
	const [dateInMs] = useState(Date.now());
	const [hideMetadata, setHideMetadata] = useState(true);
	const { displayedContent, updateBuffer, lastProcessedLength, setLastProcessedLength } =
		useInlineLinkingStreaming();

	const analyticsProperties: SummaryAnalyticsPropertiesType = {
		source: 'pageCatchup',
		additionalAnalyticsAttributes: {
			subSource: source,
		},
	};

	const originalContentVersion = selectedVersions ? Math.min(...Array.from(selectedVersions)) : 0;
	const revisedContentVersion = selectedVersions ? Math.max(...Array.from(selectedVersions)) : 0;
	const hasSelectedVersions =
		selectedVersions && selectedVersions.size > 1 && source === 'pageHistory';

	const isStreamingDone = useRef(false);
	const isStreamInitializationDone = useRef(false);
	const timeToFirstAnswerPart = useRef(0);

	const {
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultError,
		trackAIResultAction,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();

	const hasQueryParamOpenSet = useOpenContentCatchupParam() === 'true';

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;

	const { data, error: defaultMetaDataError } = useQuery<
		PageCatchupMetaDataResponseType,
		PageCatchupMetadataQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageCatchupMetadataQuery,
		{
			variables: { contentId, contentType: contentTypeEnum, endTimeMs: dateInMs },
			skip: hasSelectedVersions,
		},
	);

	const { data: versionDiffMetadata, error: versionDiffMetadataError } = useQuery<
		PageCatchupVersionDiffMetadataResponseType,
		PageCatchupVersionDiffMetadataForContentQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		PageCatchupVersionDiffMetadataForContentQuery,
		{
			variables: {
				contentId,
				contentType: contentTypeEnum,
				originalContentVersion,
				revisedContentVersion,
			},
			skip: !hasSelectedVersions,
		},
	);

	const metaDataError = defaultMetaDataError || versionDiffMetadataError;

	const isVersionDiffEmpty =
		versionDiffMetadata?.catchupVersionDiffMetadataForContent?.isDiffEmpty ?? false;

	const versionDiffUsers = versionDiffMetadata?.catchupVersionDiffMetadataForContent?.users ?? [];
	const versionDiffEditors = mapToEditors(versionDiffUsers);

	const setIsStreamingDone = () => {
		isStreamingDone.current = true;
	};

	const setIsStreamInitializationDone = () => {
		isStreamInitializationDone.current = true;
	};

	const onShowMoreClick = useCallback(() => {
		trackAIResultAction('showMoreClicked');
	}, [trackAIResultAction]);

	let agentNamedId = 'content_catchup_agent';
	let updateType = undefined;

	if (hasSelectedVersions) {
		agentNamedId = 'content_catchup_version_diff_agent';
		updateType = 'BETWEEN_VERSIONS';
	} else if (
		expValEqualsNoExposure(
			'cc_ai_consumption_pc_streaming_inline_linking',
			'cohort',
			'inline_linking_stream',
		)
	) {
		agentNamedId = 'content_catchup_streaming_inline_linking_agent';
		updateType = 'SINCE_LAST_VIEWED_MARKDOWN';
	}

	const request = useMemo(
		() => ({
			recipient_agent_named_id: agentNamedId,
			agent_input_context: {
				content_ari: pageAri ?? '',
				locale,
				end_time_ms: dateInMs,
				update_type: updateType,
				...(hasSelectedVersions && {
					original_content_version: Math.min(...Array.from(selectedVersions)),
					revised_content_version: Math.max(...Array.from(selectedVersions)),
				}),
			},
		}),
		[agentNamedId, pageAri, locale, dateInMs, updateType, hasSelectedVersions, selectedVersions],
	);

	const onStateChange = useCallback(
		(state: AIAgentStreamingState<InlineLinkingAgentResponse | string>) => {
			switch (state.responseState) {
				case AIAgentResponseState.Initialization:
					if (state.content === null) {
						experienceTracker.start({
							name: AI_CONTENT_CATCHUP_EXPERIENCE,
						});
						AI_CONTENT_CATCHUP_INTERACTION_METRIC.start();
						trackAIInteractionInit();
						timeToFirstAnswerPart.current = Date.now();
					}
					break;
				case AIAgentResponseState.Error:
					const { status_code, message_template } = state.error.message;
					AI_CONTENT_CATCHUP_INTERACTION_METRIC.stop();
					trackAIResultError({
						aiErrorCode: status_code,
						aiErrorMessage: message_template,
					});
					setIsStreamingDone();
					break;
				case AIAgentResponseState.AnswerPart:
					if (!isStreamInitializationDone.current) {
						setIsStreamInitializationDone();

						createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								action: 'streamReceived',
								actionSubject: 'pageCatchup',
								source: source ?? 'page',
								actionSubjectId: 'pageCatchupDialog',
								attributes: {
									contentId,
									timeToFirstAnswerPartInMs: Date.now() - timeToFirstAnswerPart.current,
									agentNamedId,
								},
							},
						}).fire();
					}
					const fullContent = mapInlineLinkingAgentResponseToMarkdown(
						state.content as InlineLinkingAgentResponse,
					);
					const newContent = fullContent.slice(lastProcessedLength);

					updateBuffer(newContent);
					setLastProcessedLength(fullContent.length);
					break;
				case AIAgentResponseState.FinalResponse:
					AI_CONTENT_CATCHUP_INTERACTION_METRIC.stop();
					createAnalyticsEvent({
						type: 'sendScreenEvent',
						data: {
							name: 'pageCatchupDialog',
							attributes: {
								contentId,
								aiInteractionId,
								hasQueryParamOpenSet,
							},
						},
					}).fire();
					trackAIResultView();
					setIsStreamingDone();
					break;
				default:
					break;
			}
		},
		[
			trackAIResultError,
			lastProcessedLength,
			updateBuffer,
			setLastProcessedLength,
			createAnalyticsEvent,
			contentId,
			aiInteractionId,
			hasQueryParamOpenSet,
			trackAIResultView,
			experienceTracker,
			trackAIInteractionInit,
			agentNamedId,
			source,
		],
	);

	const [{ content, responseState, error: streamSummaryError }] = useAIAgentStreaming<
		PageCatchupWrapperRequest,
		InlineLinkingAgentResponse | string
	>(
		request,
		{
			enablePartialJson: expValEqualsNoExposure(
				'cc_ai_consumption_pc_streaming_inline_linking',
				'cohort',
				'inline_linking_stream',
			),
			delayChunkUpdate: { gap: 10, time: 20 },
			headers: {
				'x-experience-id': 'content-catchup',
				'x-product': 'confluence',
			},
		},
		onStateChange,
	);

	useEffect(() => {
		if (responseState === AIAgentResponseState.FinalResponse) {
			if (hideMetadata) setHideMetadata(false);
			if (source !== 'pageHistory') {
				manuallyLogExpExposure('cc_ai_consumption_pc_streaming_inline_linking');
			}
		}
	}, [responseState, hideMetadata, source]);

	const handleContainerClick = useCallback(
		(e: MouseEvent) => {
			const target = e.target as HTMLElement;
			const linkElement = target.closest('a[id="#page-catchup-text-fragment"]');

			if (linkElement) {
				trackAIResultAction('inlineLinkClicked');
			}
		},
		[trackAIResultAction],
	);

	useEffect(() => {
		if (
			responseState === AIAgentResponseState.FinalResponse &&
			expValEqualsNoExposure(
				'cc_ai_consumption_pc_streaming_inline_linking',
				'cohort',
				'inline_linking_stream',
			)
		) {
			const container = document.querySelector('div[data-testid="ai-border-container"]');

			if (container) {
				container.addEventListener('click', handleContainerClick as EventListener);

				return () => {
					container.removeEventListener('click', handleContainerClick as EventListener);
				};
			}
		}
	}, [responseState, handleContainerClick]);

	useEffect(() => {
		return () => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'dwelled',
					actionSubject: 'pageCatchup',
					source: source ?? 'page',
					actionSubjectId: 'pageCatchupDialog',
					attributes: {
						contentId,
						dwellTime: getDwellTime(),
						aiInteractionId,
						hasQueryParamOpenSet,
					},
				},
			}).fire();

			if (!isStreamInitializationDone.current) {
				trackAIInteractionDismiss({ attributes: { dismissTiming: 'stream_initialization' } });
			} else if (!isStreamingDone.current) {
				trackAIInteractionDismiss({ attributes: { dismissTiming: 'answer_part_streaming' } });
			}
		};
		// we only want to run this effect on un-mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const lastVisitedOnDate = mapToFormattedDate(
		data?.catchupEditMetadataForContent?.lastVisitTimeISO,
		locale,
	);

	const hasVersionChangedSinceLastVisit =
		data?.catchupEditMetadataForContent?.hasVersionChangedSinceLastVisit ?? false;

	const users = data?.catchupEditMetadataForContent?.users ?? [];

	const defaultEditors = mapToEditors(users);

	const editors = source === 'pageHistory' ? versionDiffEditors : defaultEditors;

	const pageVersion = data?.content?.nodes?.[0]?.version?.number ?? -1;

	const { versionHistoryLink } = useGetVersionHistoryLink(contentId, spaceKey, pageVersion);

	const onVersionHistoryLinkClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'pageCatchup',
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId: 'pageCatchupVersionHistoryLink',
				attributes: {
					contentId,
					aiInteractionId,
					hasQueryParamOpenSet,
				},
			},
		}).fire();
		trackAIResultAction('versionHistoryLinkClick');
	}, [contentId, createAnalyticsEvent, aiInteractionId, hasQueryParamOpenSet, trackAIResultAction]);

	const pageCatchupComponentProps = {
		contentId,
		contentType,
		spaceKey,
		lastVisitedOnDate,
		editors,
		ref: catchUpContentRef,
		hideMetadata,
		versionHistoryLink,
		onVersionHistoryLinkClick,
		hasVersionChangedSinceLastVisit,
		onShowMoreClick,
		source,
		isVersionDiffEmpty,
		isInObjectSidebar,
	};

	const renderPageCatchupComponent = (contentToRender: string) => (
		<PageCatchupComponent {...pageCatchupComponentProps} content={contentToRender} />
	);

	const renderContent = () => {
		const isInInlineLinkingExperiment =
			source !== 'pageHistory' &&
			expValEqualsNoExposure(
				'cc_ai_consumption_pc_streaming_inline_linking',
				'cohort',
				'inline_linking_stream',
			);

		switch (responseState) {
			case AIAgentResponseState.Initialization:
				return isInObjectSidebar ? (
					<Box paddingInline="space.100">
						<AISummaryLoading />
					</Box>
				) : (
					<AIStreamLoading />
				);
			case AIAgentResponseState.Error:
				return (
					<SmartsSummaryErrorComponent
						analyticsProperties={analyticsProperties}
						errorFromApolloOrStream={streamSummaryError}
						contentId={contentId}
						experienceName={AI_CONTENT_CATCHUP_EXPERIENCE}
					/>
				);
			case AIAgentResponseState.AnswerPart:
				if (isInInlineLinkingExperiment) {
					return renderPageCatchupComponent(displayedContent);
				}
				return renderPageCatchupComponent(content as string);
			case AIAgentResponseState.FinalResponse:
				return renderPageCatchupComponent(content as string);
			default:
				break;
		}
	};

	return (
		<>
			{isInObjectSidebar ? (
				<>
					{metaDataError && <ErrorDisplay error={metaDataError} />}
					{renderContent()}
					{responseState === AIAgentResponseState.FinalResponse && (
						<ExperienceSuccess name={AI_CONTENT_CATCHUP_EXPERIENCE} attributes={{ source }} />
					)}
				</>
			) : (
				<AIBorder loading={responseState === AIAgentResponseState.Initialization}>
					{metaDataError && <ErrorDisplay error={metaDataError} />}
					{renderContent()}
					{responseState === AIAgentResponseState.FinalResponse && (
						<ExperienceSuccess name={AI_CONTENT_CATCHUP_EXPERIENCE} attributes={{ source }} />
					)}
				</AIBorder>
			)}
		</>
	);
};

export const PageCatchupComponentWithErrorBoundary = (props: PageCatchupWrapperComponentProps) => {
	const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
		product: 'confluence',
		subproduct: 'confluence',
		source: props.source ?? 'confluencePage',
		aiFeatureName: 'pageCatchup',
		proactiveGeneratedAI: 0,
		userGeneratedAI: 1,
	};

	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
				<PageCatchupWrapperComponent {...props} />
			</AIEventsInstrumentationProvider>
		</ErrorBoundary>
	);
};
